<template>
  <v-container class="pt-0">
    <v-row class="no-print">
      <v-col>
        <a
          :href="`https://api.riggott-ims.co.uk/dailyReturnSheets/drsPdf/${drs.id}.pdf`"
          target="_blank">
          <v-icon
            title="Generate PDF"
            class="no-print pdf-preview"
          >mdi-file-pdf-box</v-icon>
        </a>
        <!--
        <a
          :href="`https://riggott-ims-api.mmandb-dev.co.uk/dailyReturnSheets/drsPdf/${drs.id}.pdf`"
          target="_blank">
          <v-icon
            title="Generate PDF"
            class="no-print pdf-preview"
          >mdi-file-pdf-box</v-icon>
        </a>
        -->
        <v-icon
          class="no-print close-preview"
          @click="goBack()"
        >mdi-close-circle</v-icon>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="col-6 pa-0">
        <h2 class="text-orange">
          Daily Return Sheet {{ drs.job_no }}
        </h2>
      </v-col>
      <v-col class="col-6 pa-0 text-right">
        <img width="180px" src="../../assets/logo.png" alt="" />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <table style="width:100%" class="bordered border-collapse padded-table table-small-text">
          <tr>
            <td>
                <span class="text-orange">Client name:</span>
            </td>
            <td class="bordered">
                <span class="text-orange">Contract:</span>
            </td>
            <td>
                <span class="text-orange">Client Tel:</span>
                <span class="black-text">{{ drs.quotation.customer.tel }}</span>
            </td>
            <td>
                <span class="text-orange">Riggotts Contact:</span>
                <span class="black-text" v-if="drs.quotation.quoted_by_user">
                  {{ drs.quotation.quoted_by_user.name }}
                </span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
                <span class="black-text"  v-if="drs.quotation">
                  {{ drs.quotation.customer.name }}
                </span>
            </td>
            <td>
                <span class="text-orange">Contact:</span>
                <span class="black-text"  v-if="drs.quotation.contact">
                  {{ drs.quotation.contact.full_name }}
                </span>
            </td>
            <td>
                <span class="text-orange">Site Contact:</span>
                <span class="black-text"  v-if="drs.quotation">
                  {{ drs.quotation.site_contact }}
                </span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
                <span class="text-orange">Site Name:</span>
            </td>
            <td>
                <span class="text-orange">Contact Tel:</span>
                <span class="black-text"  v-if="drs.quotation.contact">
                  {{ drs.quotation.contact.tel }}
                </span>
            </td>
            <td>
                <span class="text-orange">Site Tel:</span>
                <span class="black-text"  v-if="drs.quotation">
                  {{ drs.quotation.site_tel }}
                </span>
            </td>
          </tr>
          <tr>
            <td colspan="4">
                <span class="black-text"  v-if="drs.quotation">
                  {{ drs.quotation.site_location }}
                </span>
            </td>
          </tr>
          <tr>
            <td>
                <span class="text-orange">Job date:</span>
                <span class="black-text">{{ drs.work_date | tinyDate }}</span>
            </td>
            <td>
                <span class="text-orange">Vehicle:</span>
                <span class="black-text">{{ drs.vehicle }}</span>
            </td>
            <td>
                <span class="text-orange">Site Post Code:</span>
                <span class="black-text"  v-if="drs.quotation">
                  {{ drs.quotation.site_post_code }}
                </span>
            </td>
            <td>
                <span class="text-orange">Site Email:</span>
                <span class="black-text"  v-if="drs.quotation">
                  {{ drs.quotation.site_email }}
                </span>
            </td>
          </tr>
          <tr>
            <td>
                <span class="text-orange">Description of works</span>
            </td>
            <td>
                <span class="text-orange">Mileage:</span>
                <span class="black-text">{{ drs.mileage }}</span>
            </td>
            <td>
                <span class="text-orange">DRS No:</span>
                <span v-if="drs.pal_drs_id" class="black-text">{{ drs.pal_drs_id }}</span>
                <span v-else class="black-text">{{ drs.id }}</span>
            </td>
            <td>
                &nbsp;
            </td>
          </tr>
          <tr>
            <td>
                <span class="text-orange">and materials:</span>
                <span class="black-text"  v-if="drs.quotation">
                  {{ drs.quotation.work_description }}
                </span>
            </td>
            <td class="no-pad-cell">
                <table class="no-stripe-white inset-table">
                    <tr>
                        <td>
                            <span class="text-orange">Miles to site:</span>
                            <span class="black-text"  v-if="drs.quotation">
                              {{ drs.quotation.distance }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="text-orange">Time to site:</span>
                            <span class="black-text"  v-if="drs.quotation">
                              {{ drs.quotation.drive_time }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="text-orange">Hours for job:</span>
                            <span class="black-text"  v-if="drs.quotation">
                              {{ drs.quotation.hours }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="text-orange">No of visits:</span>
                            <span class="black-text">{{  }}</span>
                        </td>
                    </tr>
                </table>
            </td>
            <td class="no-pad-cell">
                <table class="no-stripe-white inset-table">
                    <tr>
                        <td>
                            <span class="text-orange">Special Instructions:</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="black-text"  v-if="drs.quotation">
                              {{ drs.quotation.special_instructions }}
                            </span>
                        </td>
                    </tr>
                </table>
            </td>
            <td>
                <table class="no-stripe-white inset-table">
                    <tr>
                        <td>
                            <span class="text-orange">Total Job Complete:</span>
                            <span v-if="drs.job_complete === 0" class="black-text">No</span>
                            <span v-else class="black-text">Yes</span>
                        </td>
                    </tr>
                    <tr v-if="drs.work_complete === 0">
                        <td>
                            <span class="text-orange">If not complete, why?</span>
                            <span class="black-text">{{ drs.incomplete_work_reason }}</span>
                        </td>
                    </tr>
                </table>
            </td>
          </tr>
          <tr class="border-top">
            <td>
                <span class="text-orange">Type of job:</span>
                <span class="black-text">{{ drs.job_type.type }}</span>
            </td>
            <td>
                <span class="text-orange">Surface condition:</span>
                <span class="black-text" v-if="drs.surface_condition">{{ drs.surface_condition.surface_condition }}</span>
            </td>
            <td>
                <span class="text-orange">Weather condition:</span>
                <span class="black-text" v-if="drs.weather_condition">{{ drs.weather_condition.description }}</span>
            </td>
            <td>
                <span class="text-orange">Signed by:</span>
                <span class="black-text">{{ drs.sign_off_team_member_name }}</span>
            </td>
          </tr>
          <tr class="border-top">
            <td>
                <span class="text-orange">Notes:</span>
                <span class="black-text">{{ drs.notes }}</span>
            </td>
            <td>
                <span class="text-orange">Customer comments:</span>
                <span class="black-text">{{ drs.customer_comment }}</span>
            </td>
            <td>
                <span class="text-orange">Are there any disclaimers?</span>
                <span v-if="drs.disclaimers_to_record">
                  <span class="black-text" v-if="drs.disclaimers_to_record === 0">No</span>
                  <span class="black-text" v-else>Yes</span>
                </span>
            </td>
            <td>
                <span class="text-orange">Disclaimer notes:</span>
                <span class="black-text">{{ drs.incidents }}</span>
            </td>
          </tr>
          <tr class="border-top">
            <td>
              <span v-if="drs.daily_return_sheets_workers[0]">
                <span class="text-orange">Chargehand:</span>
                <span class="black-text" v-if="drs.daily_return_sheets_workers[0].crew_member">
                  {{ drs.daily_return_sheets_workers[0].crew_member.name }}
                </span>
              </span>
            </td>
            <td>
              <span v-if="drs.daily_return_sheets_workers[1]">
                <span class="text-orange">2nd Man:</span>
                <span class="black-text" v-if="drs.daily_return_sheets_workers[1].crew_member">
                  {{ drs.daily_return_sheets_workers[1].crew_member.name }}
                </span>
              </span>
            </td>
            <td>
              <span v-if="drs.daily_return_sheets_workers[2]">
                <span class="text-orange">3rd Man:</span>
                <span class="black-text" v-if="drs.daily_return_sheets_workers[2].crew_member">
                  {{ drs.daily_return_sheets_workers[2].crew_member.name }}
                </span>
              </span>
            </td>
            <td>
              <span v-if="drs.daily_return_sheets_workers[3]">
                <span class="text-orange">4th Man:</span>
                <span class="black-text" v-if="drs.daily_return_sheets_workers[3].crew_member">
                  {{ drs.daily_return_sheets_workers[3].crew_member.name }}
                </span>
              </span>
            </td>
          </tr>
          <tr class="border-top">
            <td colspan="3" style="width: 75%">
                <span class="text-orange">Item:</span>
            </td>
            <td class="border-top border-left" style="width: 25%">
                <span class="text-orange">Qty:</span>
            </td>
          </tr>
          <tr>
            <td class="pa-0" colspan="4">
              <table style="width: 100%; border-collapse:collapse;">
                <tbody v-if="sections.length === 0">
                  <tr v-for="(boq, index) in drs.daily_return_sheets_bonus_payments" :key="`boq_${index}`">
                    <td class="border-top" colspan="3" style="width: 75%">
                      <span class="black-text">{{ boq.bonus_rate.description }}</span>
                    </td>
                    <td class="border-top border-left" style="width: 25%">
                      <span class="black-text">{{ boq.quantity }}</span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(section, index) in sections" :key="`sec_${index}`">
                    <td class="pa-0">
                      <table style="border-collapse: collapse; width: 100%;">
                        <tr>
                          <td class="border-top" colspan="4"><strong>{{ section.title }}</strong></td>
                        </tr>
                        <tr v-for="(boq, index) in section.boq" :key="`secboq_${index}`">
                          <td class="border-top" colspan="3" style="width: 75%">
                            <span class="black-text">{{ boq.bonus_rate.description }}</span>
                          </td>
                          <td class="border-top border-left" style="width: 25%">
                            <span class="black-text">{{ boq.quantity }}</span>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr v-for="(bonus, index) in drs.daily_return_sheet_special_bonnuses" :key="`mu_${index}`">
            <td style="padding:0" colspan="4">
              <table style="border-collapse: collapse; width: 100%;">
                <tr>
                  <td class="border-top" colspan="3" style="width: 75%">
                    <span class="black-text">{{ bonus.description }}</span>
                  </td>
                  <td class="border-top border-left" style="width: 25%">
                    <span class="black-text">{{ bonus.quantity }}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="border-top">
              <span class="text-orange">Materials</span>
            </td>
          </tr>
          <tr>
            <td style="padding:0" colspan="4">
              <table class="no-stripe-white inset-table">
                <tr>
                  <td class="bordered"><span class="text-orange">Type:</span></td>
                  <td class="bordered"><span class="text-orange">Colour:</span></td>
                  <td class="bordered"><span class="text-orange">Batch No:</span></td>
                  <td class="bordered"><span class="text-orange">No of Bags:</span></td>
                  <td class="bordered"><span class="text-orange">Material Temp:</span></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr v-for="(material, index) in drs.daily_returns_sheets_material_usages" :key="`mu_${index}`">
            <td style="padding:0" colspan="4">
              <table class="no-stripe-white inset-table">
                <tr>
                  <td class="bordered">{{ material.type }}</td>
                  <td class="bordered">{{ material.colour }}</td>
                  <td class="bordered">{{ material.batch_number }}</td>
                  <td class="bordered">{{ material.no_of_bags }}</td>
                  <td class="bordered">{{ material.material_temperature }}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'DrsView',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      sections: [],
      drs: {
        job_type : {},
        daily_return_sheets_images: [],
        daily_return_sheets_workers: [
          { crew_member: { personnel: {} } },
        ],
        quotation: {
          customer: {
            name: '',
          }
        },
      },
    };
  },
  methods: {
    print() {
      window.print();
    },
    goBack() {
      this.$router.go('-1');
    },
    getDrs() {
      const drsId = this.$route.params.id;
      axios.get(`/dailyReturnSheets/getView/${drsId}.json?token=${this.token}`)
        .then((response) => {
          this.drs = response.data.dailyReturnSheet;
          this.sections = response.data.sections;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDrs();
  },
};
</script>
